export default [
	{
		path: '/email-groups',
		name: 'emailGroups.index',
		component: () => import(/* webpackChunkName: "emailGroups" */ '../views/Marketing/EmailGroups.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/add-email-groups',
		name: 'emailGroups.store',
		component: () => import(/* webpackChunkName: "addEmailGroups" */ '../views/Marketing/AddEmailGroups.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/email-groups/:id/edit',
		name: 'emailGroups.edit',
		component: () => import(/* webpackChunkName: "editEmailGroups" */ '../views/Marketing/EditEmailGroups.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/emails',
		name: 'emails.index',
		component: () => import(/* webpackChunkName: "emails" */ '../views/Marketing/Emails.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/add-emails',
		name: 'emails.store',
		component: () => import(/* webpackChunkName: "addEmails" */ '../views/Marketing/AddEmails.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/emails/:id/edit',
		name: 'emails.edit',
		component: () => import(/* webpackChunkName: "editEmail" */ '../views/Marketing/EditEmail.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/newsletter',
		name: 'newsletter.index',
		component: () => import(/* webpackChunkName: "newsletter" */ '../views/Marketing/Newsletter.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/newsletter-form/:id?',
		name: 'NewsletterForm',
		component: () => import(/* webpackChunkName: "newsletterForm" */ '../views/Marketing/NewsletterForm.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/campaign',
		name: 'campaign.index',
		component: () => import(/* webpackChunkName: "campaign" */ '../views/Marketing/Campaign.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/campaign-form/:id?',
		name: 'CampaignForm',
		component: () => import(/* webpackChunkName: "campaignForm" */ '../views/Marketing/CampaignForm.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/product-showcase',
		name: 'productShowcase.index',
		component: () => import(/* webpackChunkName: "proeuctShowcase" */ '../views/Marketing/ProductShowcase.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
]
