import { createRouter, createWebHistory } from 'vue-router'

import account from './account.js';
import accounting from './accounting.js';
import administration from './administration.js';
import assetManagement from './assetManagement.js';
import bank from './bank.js';
import city from './city.js';
import client from './client.js';
import contract from  './contract.js';
import country from './country.js';
import customField from './customField.js';
import defaultParams from './defaultParams.js';
import expense from './expense.js';
import expensesCategory from '@/router/expensesCategory.js';
import invoice from './invoice.js';
import order from './order.js';
import marketing from './marketing.js';
import paymentTerm from './paymentTerm.js';
import postalCode from './postalCode.js';
import product from './product.js';
import project from './project.js';
import proposal from './proposal.js';
import province from './province.js';
import quote from './quote.js';
import receivable from '@/router/receivable.js';
import report from './report.js';
import resetPassword from './resetPassword.js';
import revenueCategory from './revenueCategory.js';
import roles from './roles.js';
import scheduler from './scheduler.js'
import serial from './serial.js';
import serviceRate from './serviceRate.js';
import staff from './staff.js';
import subscriber from './subscriber.js';
import subscription from './subscription.js';
import supplier from '@/router/supplier.js';
import taskType from '@/router/taskType.js';
import tax from './tax.js';
import taxAccount from '@/router/taxAccount.js';
import ticket from '@/router/ticket.js';
import user from './user.js';

const routes = [
    {
        path: '/',
        name: 'Landing',
        component: () => import(/* webpackChunkName: "landing" */ '../views/Landing.vue'),
        meta: {
            layout: "empty",
			auth: false
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
            layout: "empty",
			auth: false,
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
            layout: "default",
            auth: true
        }
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            layout: "default",
            auth: true
        }
    },
	{
		path: '/search/:id',
		name: 'search',
		component: () => import(/* webpackChunkName: "search" */ '../views/Search/Search.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		}
	},
	{
		path: '/unauthorized',
		name: 'unauthorized',
		component: () => import(/* webpackChunkName: "unauthorized" */ '../views/Unauthorized.vue'),
		meta: {
			layout: "default",
			auth: false,
			roleTypes: ['administration', 'subscriber', 'client'],
		}
	},
	{
		path: '/:pathMatch(.*)',
		name: 'Error404',
		component: () => import(/* webpackChunkName: "error404" */ '../views/Error404.vue'),
		meta: {
			layout: "default",
			auth: true
		}
	},
	...account,
	...accounting,
	...administration,
	...assetManagement,
	...bank,
	...city,
	...client,
	...contract,
	...country,
	...customField,
	...defaultParams,
	...expense,
	...expensesCategory,
	...invoice,
	...order,
	...marketing,
	...paymentTerm,
	...postalCode,
	...product,
	...proposal,
	...project,
	...province,
	...quote,
	...receivable,
	...report,
	...resetPassword,
	...revenueCategory,
	...roles,
	...scheduler,
	...serial,
	...serviceRate,
	...staff,
	...subscriber,
	...subscription,
	...supplier,
	...taskType,
	...tax,
	...taxAccount,
	...ticket,
	...user,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
})

router.beforeEach((to, from, next) => {
	const requiresAuth = to.meta.auth;
	const token = localStorage.getItem('access_token');
	const user = JSON.parse(localStorage.getItem('auth_user'));

	if (requiresAuth && (!token || !user)) {
		next('/login');
	} else {
		if (to.meta.roles || to.meta.roleTypes) {
			const userRole = user.role_slug;
			const userRoleType = user.role_type;

			if (to.meta.roles && !to.meta.roles.includes(userRole)) {
				next({name: 'unauthorized'});
			} else if (to.meta.roleTypes && !to.meta.roleTypes.includes(userRoleType)) {
				next({name: 'unauthorized'});
			} else {
				next();
			}
		} else {
			next();
		}
	}
});

export default router
