export default [
	{
		path: '/postal-code',
		name: 'PostalCode',
		component: () => import(/* webpackChunkName: "postalCode" */ '../views/PostalCode/PostalCode.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
]
