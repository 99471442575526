<template>
    <ul class="inline-flex text-white text-xl lang-selector mt-4">
        <li ><a @click.prevent="toFrench" class="text-white p-4 cursor-pointer">FR</a></li>
        <li ><a @click.prevent="toEnglish" class="text-white p-4 cursor-pointer">EN</a></li>
        <li ><a @click.prevent="toSpanish" class="text-white p-4 cursor-pointer">ES</a></li>
    </ul>
</template>

<script>
    export default {
        name: 'LocaleSelector',

        methods: {
            toEnglish() {
                this.emitter.emit('set-locale', 'en');
				localStorage.setItem('currentLocale', 'en');
            },

            toFrench() {
                this.emitter.emit('set-locale', 'fr');
				localStorage.setItem('currentLocale', 'fr');
            },

            toSpanish() {
                this.emitter.emit('set-locale', 'es');
				localStorage.setItem('currentLocale', 'es');
            }
        }
    };
</script>
