export default [
	{
		path: '/import-export',
		name: 'ImportExport',
		component: () => import(/* webpackChunkName: "importExport" */ '../views/Administration/ImportExport.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},

]
